//import "es6-promise/auto";
import { v4 as uuidv4 } from 'uuid';
import Uploader from './uploader';
import {getFingerprintingV4Features} from './fingerprint_v4';
import Config from './config';
import dcopy from 'deep-copy';
import * as debug from './debugger';
import {base64Encode, byteLength, equalArray, getGlobalUserId, getKeyCode, lzStringEncode, maxNumber, parseInt} from './utils';
import {picassoCanvas} from "./canvas";

// import {botDetect, ruleList} from './rules';

let targetEvents = [];

let movementEvents = [
    "mousemove",
    "mousedown",
    "mouseup",
    "click",
    "dblclick",
    "contextmenu",
    "wheel",
    "touchstart",
    "touchmove",
    "touchend",
    "resize"
];

let keyboardEvents = [
    "keydown",
    "keyup",
];

let fingerprintFeatureList = [
    'navigator.onLine == ture',
    'connection.rtt === 0',
    'Webdriver == true',
    'chrome element present',
    'safari element present',
    'opera element present',
    'brave element present',
];

let pageLoadTime = new Date();

let errorHandler = function (e) {
    let err = base64Encode(e.stack.substr(0, 1000));
    (new Image).src = Config.bingProduction ?
                        `${_G.lsUrl}&Type=Event.MSRML&DATA={"type":"error","msg":"${err}"}` :
                        `https://www.bing.com/afdml072020?type=error&data=${err}`;
};

function isIEBrowser(upperBound = 9) {
    let ua = navigator.userAgent.toLowerCase();
    let v = ua.match(/msie ([\d]+)/);
    return (v && parseInt(v[1]) <= upperBound);
}

function getButton(btn) {
    if (btn === '2') {
        return 'Right';
    } else {
        return "";
    }
}

class Mouselog {
    constructor(config, debugOutputElementId = "mouselogDebugDiv") {
        try {
            this.config = new Config();
            this.mouselogLoadTime = new Date();

            this.batchCount = 0;
            this.packetCount = 0;

            this.eventsList = [];
            this.lastEvtInfo;
            this.eventsCount = 0;
            this.uploadInterval; // For "periodic" upload mode
            this.uploadTimeout; // For "mixed" upload mode

            this.browser_plugins = [];
            for (let i = 0; i < navigator.plugins.length; i++)
                if (navigator.plugins[i])
                    this.browser_plugins.push(navigator.plugins[i].name);
            this.browser_mimeTypes = [];
            for (let i = 0; i < navigator.mimeTypes.length; i++)
                if (navigator.mimeTypes[i])
                    this.browser_mimeTypes.push(navigator.mimeTypes[i].type);
            this.run(config, debugOutputElementId);
        }
        catch (e) {
            errorHandler(e)
        }
    }

    _initImpressionId() {
        if (this.config.impIdVariable === undefined || this.config.impIdVariable === null) {
            this.impressionId = uuidv4();
        } else {
            try {
                this.impressionId = this.config.impIdVariable;
                if (this.impressionId === null || this.impressionId === undefined) {
                    // debug.write(`Global varialbe impIdVariable: ${this.config.impIdVariable} is ${this.impressionId}.`);
                    this.impressionId = `Err_${this.config.impIdVariable}_is_${this.impressionId}`;
                }
            } catch (e) {
                // debug.write("Fail to initialize Impression ID with a `impIdVariable`");
                this.impressionId = `Err_fail_to_get_${this.config.impIdVariable}`;
            }
        }
    }

    _clearBuffer() {
        this.eventsList = [];
    }

    getPicassoCanvasHash() {
        const numShapes = 5;
        const initialSeed = Math.floor(8491);
        const params = {
            area: {
                width: 300,
                height: 300,
            },
            offsetParameter: 2001000001,
            fontSizeFactor: 1.5,
            multiplier: 15000,
            maxShadowBlur: 50,
        };

        return picassoCanvas(numShapes, initialSeed, params);
    }

    _newDataBatch() {
        let x = 0; //   bit1               bit2           bit3            bit4            bit5          bit6           bit7
        //  navigator.onLine  rtt === 0      webdriver   chrome element  safari element  opera element   brave element
        let c = 1;
        if (navigator.onLine) {
            x = x | c;
        }
        c <<= 1;
        if (navigator.connection && navigator.connection.rtt === 0) {
            x = x | c;
        }
        c <<= 1;
        if (navigator.webdriver) {
            x = x | c;
        }
        c <<= 1;
        if (window.chrome !== undefined) {
            x = x | c;
        }
        c <<= 1;
        if (window.safari !== undefined) {
            x = x | c;
        }
        c <<= 1;
        if (window.opera !== undefined) { // The new Opera 30 release now fully uses Chromium, so don't have opera object
            x = x | c;
        }
        c <<= 1;
        if (window.brave !== undefined) {
            x = x | c;
        }

        let fingerprint_features = {
            a: navigator.languages, // languages
            b: this.browser_plugins, // plugins
            c: this.browser_mimeTypes, // mimeTypes
            d: x, // see declaration of x
            e: window.outerHeight, // outerHeight
            f: window.outerWidth, // outerWidth
            g: navigator.appVersion.substr(0, 300), // appVersion
            // browser fingerprint
            h: navigator.platform, // platform
            i: navigator.cpuClass, // cpuClass (IE only)
            j: navigator.oscpu,  // oscpu (Firefox only)
            k: navigator.productSub, // prosub (Chrome: ‘20030107’ , FireFox: ‘20100101’, IE: undefined)
            l: "", // eval.toString(), removed now
        };

        debug.write(`UserAgent: ${navigator.userAgent}`);

        // let bot_flag = botDetect(navigator.userAgent, navigator.appVersion, fingerprint_features);

        let trace = {
            a: this.batchCount, // batchId
            b: 0, // packetId
            c: window.location.hostname ? window.location.hostname : "localhost", // url
            d: window.location.pathname, // path
            e: maxNumber(document.body.scrollWidth, window.innerWidth), // width
            f: maxNumber(document.body.scrollHeight, window.innerHeight), // height
            // g: bot_flag, // bot_flag
            h: pageLoadTime, // pageLoadTime
            i: document.referrer, // referrer
            j: navigator.userAgent.substr(0, 300), // userAgent
            n: this.config.version // version
        };
        if (this.config.fullFeatures) {
            trace.k = fingerprint_features;
            trace.o = this.getPicassoCanvasHash();
            trace.p = getFingerprintingV4Features();
        }
        this.batchCount += 1;
        return trace;
    }

    _mouseHandler(evt) {
        // _mouseHandler is a callback function
        // To catch the internal exception, this function should be wrapped by a try-catch block.
        try {
            // PC's Chrome on Mobile mode can still receive "contextmenu" event with zero X, Y, so we ignore these events.
            if (evt.type === 'contextmenu' && evt.pageX === 0 && evt.pageY === 0) {
                return;
            }
            // (id, event type, timestamp)
            let evtInfo = [this.eventsCount, targetEvents.indexOf(evt.type), Math.floor(evt.timeStamp) / 1000];
            switch (evt.type) {
                case "mousemove": // (x,y)
                    let x = parseInt(evt.pageX);
                    let y = parseInt(evt.pageY);
                    evtInfo.push(x, y);
                    break;
                case "touchmove":
                case "touchstart":
                case "touchend":    // (x,y)
                    x = parseInt(evt.changedTouches[0].pageX);
                    y = parseInt(evt.changedTouches[0].pageY);
                    evtInfo.push(x, y);
                    break;
                case "wheel": // (x,y,deltaX,deltaY)
                    x = parseInt(evt.pageX);
                    y = parseInt(evt.pageY);
                    let deltaX = parseInt(evt.deltaX);
                    let deltaY = parseInt(evt.deltaY);
                    evtInfo.push(x, y, deltaX, deltaY);
                    break;
                case "mouseup":
                case "mousedown":
                case "click":
                case "dblclick":
                case "contextmenu": // (x,y,button)
                    x = parseInt(evt.pageX);
                    y = parseInt(evt.pageY);
                    let btn = getButton(evt.buttono);
                    evtInfo.push(x, y, btn);
                    break;
                case "resize": // (width,height)
                    let width = evt.target.innerWidth;
                    let height = evt.target.innerHeight;
                    evtInfo.push(width, height)
                    break;
                case "keydown":
                case 'keyup': // keytype
                    let keyCode = getKeyCode(evt);
                    if (keyCode >= 96 && keyCode <= 105) { // set Numpad key to 96
                        keyCode = 96;
                    } else if (keyCode >= 48 && keyCode <= 57) { // set Digit key to 48
                        keyCode = 48;
                    } else if (keyCode >= 65 && keyCode <= 90) { // set Alphabet key to 65
                        keyCode = 65;
                    }
                    evtInfo.push(keyCode);
                    debug.write(evt.type + " " + JSON.stringify(evtInfo));
                    break;
            }

            // Remove Redundant events
            if (this.lastEvtInfo && equalArray(this.lastEvtInfo, evtInfo)) {
                return;
            }
            // Remove two consecutive Mousemove/Touchmove events with the same x and y
            if (this.lastEvtInfo && (targetEvents[evtInfo[1]] == "mousemove" || targetEvents[evtInfo[1]] == "touchmove") && this.lastEvtInfo[1] == evtInfo[1] && equalArray(this.lastEvtInfo.slice(3), evtInfo.slice(3))) {
                return;
            }

            this.eventsList.push(evtInfo);
            this.lastEvtInfo = evtInfo;
            this.eventsCount += 1;

            if (this.config.uploadMode == "event-triggered" && this.eventsList.length % this.config.frequency == 0) {
                this._uploadData();
            }

            if (this.config.uploadMode == "mixed" && this.eventsList.length % this.config.frequency == 0) {
                this._periodUploadTimeout();
                this._uploadData();
            }
        } catch (e) {
            errorHandler(e);
        }
    }

    _encodeData(data) {
        let encodedData = JSON.stringify(data);
        const encoderConfig = this.config.encoder.toLowerCase();
        if (encoderConfig === "base64") {
            encodedData = base64Encode(encodedData);
        } else if(encoderConfig === "lzstring") {
            encodedData = lzStringEncode(encodedData);
        }
        return encodedData;
    }

    _binarySplitBigDataBlock(dataBlock) {
        let encodedData = this._encodeData(dataBlock);
        let res = [];
        if (byteLength(encodedData) >= this.config.sizeLimit) {
            let newDataBlock = dcopy(dataBlock);
            dataBlock.l.splice(dataBlock.l.length / 2);
            newDataBlock.l.splice(0, newDataBlock.l.length / 2);
            this._binarySplitBigDataBlock(dataBlock).forEach(data => {
                res.push(data);
            });
            this._binarySplitBigDataBlock(newDataBlock).forEach(data => {
                res.push(data);
            });

        } else {
            res.push(dataBlock);
        }
        return res;
    }

    _sendPingMessage() {
        // Upload an ping message with empty trace
        let trace = this._newDataBatch();
        this.batchCount -= 1;
        trace.packetId = -1;
        try {
            this.uploader.upload(trace, this._encodeData(trace), "ping");
        }
        catch (e) {
            errorHandler(e);
        }
    }

    _uploadData() {
        try {
            if (this.config.uploadTimes && this.batchCount >= this.config.uploadTimes) {
                return;
                // TODO: This is only a stopgap method, a better method is to stop mouselog entirely.
            }
            let data = this._newDataBatch();
            if (this.config.recordMovementEvent || this.config.recordKeyboardEvent) {
                data.l = this.eventsList;
                this.eventsList = [];
            }
            let dataList = this._binarySplitBigDataBlock(data); // An array of data blocks
            dataList.forEach(data => {
                data.m = this.packetCount;
                this.packetCount += 1;
                debug.write(`<br/>Original data:<br/>${JSON.stringify(data, null, 2)}<br/>`);
                let encodedData = this._encodeData(data);
                debug.write(`<br />Encoded data:<br />${encodedData}<br /><br />`);
                this.uploader.upload(data, encodedData);
            });
            if (data['k']) {
                debug.write("=============== Fingerprinting Features Start =================");
                for (let i = 0; i < fingerprintFeatureList.length; i++) {
                    if ((data['k'].d & (1 << i)) > 0) {
                        debug.write(`Fingerprinting Feature: ${fingerprintFeatureList[i]}`);
                    }
                }
                debug.write("=============== Fingerprinting Features End   =================");
            }
            /*
            debug.write("=============== Fingerprinting Rules Start =================");

            for (let i = 0; i < ruleList.length; i ++) {
                if ((data['g'] & (1<<i)) > 0) {
                    debug.write(`Fingerprinting rule: ${ruleList[i]}`);
                }
            }
            debug.write("=============== Fingerprinting Rules End   =================");
            */
        }
        catch (e) {
            this.batchCount += 1;
            errorHandler(e);
        }
    }

    _periodUploadTimeout() {
        clearInterval(this.uploadTimeout);
        this.uploadTimeout = setInterval(() => {
            clearInterval(this.uploadTimeout);
            if (this.config.enableSendEmpty || this.eventsList.length > 0) {
                this._uploadData();
            }
        }, this.config.uploadPeriod);
    }

    _periodUploadInterval() {
        clearInterval(this.uploadInterval);
        this.uploadInterval = setInterval(() => {
            if (this.config.enableSendEmpty || this.eventsList.length > 0) {
                this._uploadData();
            }
        }, this.config.uploadPeriod);
    }

    _runCollector() {
        targetEvents.forEach(s => {
            this.config.scope.addEventListener(s, (evt) => this._mouseHandler(evt));
        });

        if (this.config.uploadMode === "periodic") {
            this._periodUploadInterval();
        }

        if (this.config.uploadMode === "mixed") {
            this._periodUploadTimeout();
        }
    }

    _stopCollector() {
        targetEvents.forEach(s => {
            this.config.scope.removeEventListener(s, (evt) => this._mouseHandler(evt));
        });
        clearInterval(this.uploadInterval);
        clearInterval(this.uploadTimeout);
    }

    _resetCollector() {
        this._stopCollector();
        this._runCollector();
    }

    _init(config) {
        this._clearBuffer();
        if (!this.config.build(config)) {
            return { status: -1, msg: `Invalid configuration.` };
        }
        this._initImpressionId();
        this.uploader = new Uploader(this.impressionId, this.config);
        window.onunload = () => {
            if (this.eventsList.length != 0) {
                this._uploadData();
            }
        };
        if (this.config.recordMovementEvent) {
            targetEvents = targetEvents.concat(movementEvents);
        }
        if (this.config.recordKeyboardEvent) {
            targetEvents = targetEvents.concat(keyboardEvents);
        }
        return { status: 0 };
    }

    _pause() {
        this._stopCollector();
    }

    _resume() {
        this._runCollector();
    }

    run(config, debugOutputElementId) {
        if (debugOutputElementId)
            debug.activate(debugOutputElementId);
        if (isIEBrowser(9)) {
            debug.write("IE Browser version <= 9. Stop.");
            return;
        }
        let res = this._init(config);
        if (!this.config.disableException) {
            this.errorHandler = function (e) {
                throw e;
            };
        }
        if (res.status === 0) {
            // if (visibilityChangeEvent) {
            //     document.addEventListener(visibilityChangeEvent, (evt) => this._onVisibilityChange(evt));
            // }
            if (this.config.recordMovementEvent || this.config.recordKeyboardEvent) {
                this._runCollector();
                this.uploader.start(this.impressionId);
            }
            else {
                this._uploadData();
            }
            debug.write("Mouselog agent is activated!");
            debug.write(`Website ID: ${this.config.websiteId}`);
            if (!this.impressionId.startsWith("Err_")) {
                debug.write(`Impression ID: ${this.impressionId}`);
            }
            debug.write(`User-Agent: ${navigator.userAgent}`);
            debug.write(`User ID: ${getGlobalUserId()}`);
            debug.write(`Page load time: ${pageLoadTime}`);

            if (this.config.enablePingMessage) {
                debug.write("Send ping Message..");
                this._sendPingMessage();
            }
        } else {
            debug.write(res.msg);
            debug.write("Fail to initialize Mouselog agent.");
        }
    }

    stop() {
        this.uploader.stop();
        this._stopCollector();
        this._clearBuffer();
        if (!this.impressionId.startsWith("Err_")) {
            debug.write(`Mouselog agent ${this.impressionId} is stopped!`);
        }
        else {
            debug.write(`Mouselog agent is stopped!`);
        }
    }
}

export default Mouselog;
