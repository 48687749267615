import urljoin from 'url-join';
import * as debug from './debugger';

class Config {
    // Set up a default config
    constructor() {
        // Type: number
        this.version = "4,8";

        // Type: string, REQUIRED
        // Endpoint Url
        this.uploadEndpoint = "https://www.bing.com/afdml072020";

        // An array "mixed", "periodic", "event-triggered"
        // Periodic mode: Upload data in every given period
        // Event-triggered mode: Upload data when a given number of events are collected
        // Mixed mode: a mix of periodic and event-triggered mode
        this.uploadMode = "mixed";

        // Type: number
        // If `uploadMode` is "mixed", "periodic", data will be uploaded every `uploadPeriod` ms.
        // If no data are collected in a period, no data will be uploaded
        this.uploadPeriod = 30000;

        // Type: number
        // If `uploadMode` == "event-triggered"
        // The website interaction data will be uploaded when every `frequency` events are captured.
        this.frequency = 50;

        // Type: number | null
        // Mouselog will stop uploading data after uploading `uploadTimes` batch data.
        this.uploadTimes = 1;

        // Maximum size of a single package
        this.sizeLimit = 7000;

        // Type: number
        // Time interval for resending the failed trace data
        this.resendInterval = 20000;

        // Type: HTML DOM Element
        // Capture the events occur in `this.scope`
        this.scope = window.document;

        // Content: "base64" or an empty string
        // Use a encoder before uploading the data
        this.encoder = "lzstring";

        // Type: Boolean
        // Allow mouselog to send data without any events
        this.enableSendEmpty = true;

        // Type: Boolean
        // Not allow internal exceptions to be raised in browser's console
        this.disableException = true;

        // Type: string
        // A global predefined variable for setting the impression ID.
        // When initializing the impression ID, mouselog will try to use this.impIdVariable.
        // Warning: Please don't set the same impression ID variable in two different mouselog instances.
        this.impIdVariable = null;

        // These parameters are required for runing a Mouselog agent

        // These parameters will be ignored when updating config from Mouselog server
        this._ignoredParams = [
            "scope",
            "impIdVariable",
            "disableException"
        ];

        // Type: Boolean
        // If `enablePingMessage`, Mouselog will send a ping message with empty trace immediately after initialization
        this.enablePingMessage = false;

        // Type: Boolean
        // If `recordKeyboardEvent`, Mouselog will record masked keyboard event
        this.recordKeyboardEvent = false;

        // Type: Boolean
        // If `recordMovementEvent`, Mouselog will record movementEvent (mouse, touch) event
        this.recordMovementEvent = false;

        // Type: Boolean
        // If `fullFeatures`, Mouselog will send all fingerprinting features with data package
        this.fullFeatures = true;

        // Type: Boolean
        // If `bingProduction`, Mouselog will use Bing instrumentation library to send data available in SLAPI
        this.bingProduction = Config.bingProduction;
    }

    // Declaring a static constant environmental config only modifiable through compile time
    static get bingProduction() {
        return true;
    }

    build(config, isUpdating = false) {
        try {
            // Overwrite the default config
            if (config) {
                Object.keys(config).forEach(key => {
                    // Overwriting Class private members / function method is not allowed
                    if (this[key] !== undefined && key[0] != "_" && typeof (this[key]) != "function") {
                        // Do not update some `ignored` parameter from server configuration
                        // PS: Dont use "array.includes" because it is not supported in IE
                        if (!(isUpdating && this._ignoredParams.indexOf(key) != -1)) {
                            this[key] = config[key];
                        }
                    }
                });
            }
            this._formatUrl();
        } catch (err) {
            debug.write(err);
            return false;
        }
        return true;
    }

    update(config) {
        return this.build(config, true);
    }

    _formatUrl() {
        if (this.uploadEndpoint.substr(0, 8) !== "https://" || this.uploadEndpoint.substr(0, 7) !== "http://") {
            this.absoluteUrl = this.uploadEndpoint;
        } else {
            this.absoluteUrl = urljoin(window.location.origin, this.uploadEndpoint);
        }
    }
}
export default Config;
