import * as v4 from "./fingerprint_v4_extract";

var removeArray = ['onchange', 'constructor', 'addEventListener', 'dispatchEvent', 'removeEventListener', 'constructor', 'constructor', '__defineGetter__', '__defineSetter__', 'hasOwnProperty', '__lookupGetter__', '__lookupSetter__', 'isPrototypeOf', 'propertyIsEnumerable', 'toString', 'valueOf', '__proto__', 'toLocaleString'];

function excludeFromArr(arr, exclude) {
  arr.splice(arr.indexOf(exclude), 1);
  return arr;
}

// https://stackoverflow.com/questions/17776830/looping-through-all-of-the-items-in-the-window-object
function getProperties(obj) {
  var res = [];
  do Object.getOwnPropertyNames(obj).forEach(function (name) {
    res.push(name);
  });
  while (obj = Object.getPrototypeOf(obj));

  res = excludeFromArr(res, removeArray);
  return res;
}

function getObjectFromArray(feature, array) {
  var res = {};
  array.forEach(function (property) {
    if (["caller", "callee", "arguments"].indexOf(property) === -1) {
      res[property] = feature[property];
    }
  });
  return res;
}

function featureToString(feature) {
  try {
    if (typeof (feature) === "number" || typeof (feature) === "boolean" || typeof (feature) === "string") {
      return feature.toString();
    } else if (feature === undefined || feature === null) {
      return "undefined";
    }

    var array = getProperties(feature);
    var obj = getObjectFromArray(feature, array);
    return JSON.stringify(obj);
  } catch (e) {
    return e.message;
  }
}

// This function takes a featureContainer (top level property name such as navigator or locally defined variable such as timeZone)
// and an evalExpression and calls eval function on it. For simple variable evalExpression is empty string, for complex property, evalExpression is the property name.
// For example to evaluate navigator.vendor, you pass navigator as featureContainer and vendor as evalExpression. 
// To evaluate a locally defined variable timeZone, you pass timeZone as featureContainer and empty string as evalExpression.
// For this function to work, we need to make sure that obfuscation does not change the name of the featureContainer. In obfuscate.js, 
// we make sure that featureContainer is added to reservedNames and reservedStrings.
// function evaluateFeature(featureContainer, evalExpression) {
//   var feature = undefined;
//   try {
//     evalExpression = evalExpression === "" ? "featureContainer" : "featureContainer." + evalExpression;
//     feature = eval(evalExpression);
//   } catch (e) {
//     if (e.message.indexOf("is not defined") !== -1) {
//       return "undefined";
//     } else {
//       return e.message;
//     }
//   }
//   return featureToString(feature); 
// }

export function getFingerprintingV4Features() {
  var timeZone = v4.getTimeZone();
  var webgl = v4.getWebglFingerprint();
  var hdr = v4.isHdr();
  var openDatabase = v4.getOpenDatabase();
  var vendorFlavors = v4.getVendorFlavors();
  var cookiesEnabled = v4.areCookiesEnabled();
  var colorGamut = v4.getColorGamut();
  var invertedColors = v4.areColorsInverted();
  var forcedColors = v4.areColorsForced();

  return {
    a: featureToString(window?.devicePixelRatio),
    b: featureToString(navigator?.maxTouchPoints),
    c: featureToString(navigator?.vendor),
    d: featureToString(window?.screen),
    e: featureToString(navigator?.pdfViewerEnabled),
    f: featureToString(navigator?.userAgentData !== undefined),
    g: featureToString(navigator?.buildID),
    h: featureToString(navigator?.connection !== undefined),
    i: featureToString(navigator?.contacts !== undefined),
    j: featureToString(navigator?.cookieEnabled),
    k: featureToString(navigator?.deviceMemory),
    l: featureToString(navigator?.hardwareConcurrency),
    m: featureToString(navigator?.hid !== undefined),
    n: featureToString(navigator?.keyboard !== undefined),
    o: featureToString(navigator?.presentation !== undefined),
    p: featureToString(navigator?.serial !== undefined),
    q: featureToString(navigator?.wakeLock),
    r: featureToString(navigator?.windowControlsOverlay),
    s: featureToString(navigator?.xr),
    t: featureToString(timeZone),
    u: featureToString(webgl.vendor),
    v: featureToString(webgl?.renderer),
    w: featureToString(webgl?.vendor2),
    x: featureToString(webgl?.renderer2),
    y: featureToString(webgl?.version),
    z: featureToString(webgl?.shadingLanguageVersion),
    a2: featureToString(hdr),
    b2: featureToString(openDatabase),
    c2: featureToString(vendorFlavors),
    d2: featureToString(cookiesEnabled),
    e2: featureToString(colorGamut),
    f2: featureToString(invertedColors),
    g2: featureToString(forcedColors)
  };
}
