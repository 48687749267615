export function getTimeZone() {
  if (typeof Intl === "undefined") {
    return undefined;
  } else {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}

export function getWebglFingerprint() {
  var gl = document.createElement('canvas').getContext('webgl');
  if (!gl) {
    return {};
  }
  var debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
  return {
    vendor: !debugInfo ? undefined : gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL),
    renderer: !debugInfo ? undefined : gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL),
    vendor2: gl.getParameter(gl.VENDOR),
    renderer2: gl.getParameter(gl.RENDERER),
    version: gl.getParameter(gl.VERSION),
    shadingLanguageVersion: gl.getParameter(gl.SHADING_LANGUAGE_VERSION),
  };
}

export function isHdr() {
  if (typeof matchMedia === "undefined") {
    return undefined;
  }

  if (doesMatch_hdr('high')) {
    return true;
  }
  if (doesMatch_hdr('standard')) {
    return false;
  }
  return undefined;
}

function doesMatch_hdr(value) {
  return matchMedia("(dynamic-range: ".concat(value, ")")).matches;
}

export function getOpenDatabase() {
  return !!window.openDatabase;
}

export function getVendorFlavors() {
  var flavors = [];
  for (var _i = 0, _a = [
    'chrome',
    'safari',
    '__crWeb',
    '__gCrWeb',
    'yandex',
    '__yb',
    '__ybro',
    '__firefox__',
    '__edgeTrackingPreventionStatistics',
    'webkit',
    'oprt',
    'samsungAr',
    'ucweb',
    'UCShellJava',
    'puffinDevice',
  ]; _i < _a.length; _i++) {
    var key = _a[_i];
    var value = window[key];
    if (value && typeof value === 'object') {
      flavors.push(key);
    }
  }
  return flavors.sort();
}

export function areCookiesEnabled() {
  var d = document;
  // Taken from here: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/cookies.js
  // navigator.cookieEnabled cannot detect custom or nuanced cookie blocking configurations. For example,  when blocking
  // cookies via the Advanced Privacy Settings in IE9, it always returns true. And there have been issues in the past
  // with site-specific exceptions. Don't rely on it.
  // try..catch because some in situations `document.cookie` is exposed but throws a
  // SecurityError if you try to access it; e.g. documents created from data URIs
  // or in sandboxed iframes (depending on flags/context)
  try {
    // Create cookie
    d.cookie = 'cookietest=1; SameSite=Strict;';
    var result = d.cookie.indexOf('cookietest=') !== -1;
    // Delete cookie
    d.cookie = 'cookietest=1; SameSite=Strict; expires=Thu, 01-Jan-1970 00:00:01 GMT';
    return result;
  } catch (e) {
    return false;
  }
}

export function getColorGamut() {
  if (typeof matchMedia === "undefined") {
    return undefined;
  }

  // rec2020 includes p3 and p3 includes srgb
  for (var _i = 0, _a = ['rec2020', 'p3', 'srgb']; _i < _a.length; _i++) {
    var gamut = _a[_i];
    if (matchMedia("(color-gamut: ".concat(gamut, ")")).matches) {
      return gamut;
    }
  }
  return undefined;
}

export function areColorsInverted() {
  if (typeof matchMedia === "undefined") {
    return undefined;
  }

  if (doesMatch_color('inverted')) {
    return true;
  }
  if (doesMatch_color('none')) {
    return false;
  }
  return undefined;
}

function doesMatch_color(value) {
  return matchMedia("(inverted-colors: ".concat(value, ")")).matches;
}

export function areColorsForced() {
  if (typeof matchMedia === "undefined") {
    return undefined;
  }

  if (doesMatch_force('active')) {
    return true;
  }
  if (doesMatch_force('none')) {
    return false;
  }
  return undefined;
}

function doesMatch_force(value) {
  return matchMedia("(forced-colors: ".concat(value, ")")).matches;
}
