import urljoin from 'url-join';
import * as debug from './debugger';
import { getGlobalUserId } from './utils';

let StatusEnum = {
    WAITING: 0,
    SENDING: 1,
    SUCCESS: 2,
};

// https://stackoverflow.com/questions/36213455/add-startswith-in-ie-11
if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
        value: function(search, rawPos) {
            var pos = rawPos > 0 ? rawPos|0 : 0;
            return this.substring(pos, pos + search.length) === search;
        }
    });
}

class Uploader {
    constructor(impId, config) {
        this.impressionId = impId;
        this.config = config;
        this.resendQueue = [];
    }

    start() {
        this.resendInterval = setInterval(() => {
            this._resendFailedData.call(this);
        }, this.config.resendInterval);
    }

    stop() {
        clearInterval(this.resendInterval);
        // TODO?: Send all the remaining data in this.buf
    }

    upload(data, encodedData, msgType = "data") {
        // resolve({status:-1/0/1, ...}): uploading success/fail.
        // reject(ErrorMessage): Errors occur when updating the config.
        let url = null;
        if (this.impressionId.startsWith("Err_")) {
            url = urljoin(
                this.config.absoluteUrl,
                `?type=${msgType}`
            );
        }
        else {
            const userId = getGlobalUserId();
            url = urljoin(
                this.config.absoluteUrl,
                `?impressionId=${this.impressionId}${userId !== "" ? `&userId=${userId}` : ""}&type=${msgType}`
            );
        }
        if (msgType === 'ping') { // Using image object to send ping messages, may improve compatibility
            (new Image).src = this.config.bingProduction ?
                                `${_G.lsUrl}&Type=Event.MSRML&DATA={"v":"${this.config.version}","type":"ping"}` :
                                url;
        }
        else if (msgType === "error") {
            if (this.config.bingProduction) {
                this.postDataToBing(encodedData);
            }
            else {
                (new Image).src = `${url}&data=${encodedData}`;
            }
        }
        else {
            debug.write(`Uploading Pkg ${data.m}, window size: ${data.e}*${data.f}`);
            if (this.config.recordKeyboardEvent || this.config.recordMovementEvent) {
                debug.write(`events count: ${data.l.length}`);
                for (let i = 0; i < 3 && i < data.l.length; ++i)
                debug.write(`    ${JSON.stringify(data.l[i])}`);
            }
            if (this.config.bingProduction) {
                this.postDataToBing(encodedData);
            }
            else {
                let img = new Image;
                img.onerror = () => {
                    debug.write(`Pkg ${data.m} failed, wait for resending.`);
                    this._appendFailedData(data, encodedData, msgType);
                };
                img.src = `${url}&data=${encodedData}`;
            }
        }
    }

    postDataToBing(data) {
        if (typeof window["Log2"] !== "undefined" && window["Log2"].LogEvent) {
            const payload = {
                "T": "CI.MsraMouseLogV4",
                "FID": "CI",
                "L": data
            }

            window["Log2"].LogEvent("ClientInst", payload, null, null, null, null, null, null);
            window["Log2"].ForceFlush();
        }
        else {
            const url = "/fd/ls/lsp.aspx";
            const time = +new Date;
            const payload = `{"T":"CI.MsraMouseLogV4","FID":"CI","L":"${data}"}`;
            const eventXML = `<E><T>Event.ClientInst</T><IG>${_G.IG}</IG><TS>${time}</TS><D><![CDATA[[${payload.replace("]]>", "]]]]><![CDATA[>")}]]]></D></E>`;
            const xml = `<ClientInstRequest><Events>${eventXML}</Events><STS>${time}</STS></ClientInstRequest>`;

            let sendBeaconOk = false;
            if (navigator && navigator["sendBeacon"]) {
                const blob = new Blob([xml], { type: "text/plain" });
                sendBeaconOk = navigator["sendBeacon"](url, blob);
            }

            if (!sendBeaconOk) {
                let request = sj_gx();
                request.open("POST", url, true);
                request.setRequestHeader("Content-Type", "text/xml");
                request.send(xml);
            }
        }
    }

    setConfig(config) {
        this.stop();
        this.config = config;
        this.start();
    }

    _resendFailedData() {
        if (this.resendQueue.length > 0) {
            debug.write("Resending data...");
        }
        while (this.resendQueue.length > 0) {
            let obj = this.resendQueue[0];
            debug.write(`Resending Pkg ${obj.data.packetId}`);
            this.upload(obj.data, obj.encodedData, obj.type);
            this.resendQueue.splice(0, 1);
        }
    }

    _appendFailedData(data, encodedData, type) {
        this.resendQueue.push({
            status: StatusEnum.WAITING,
            data: data,
            encodedData: encodedData,
            type: type
        });
    }
}

export default Uploader;
