import LZString from 'lz-string';

export function maxNumber(...nums) {
    let res = nums[0];
    for (let i = 1; i < nums.length; ++i) {
        res = res > nums[i] ? res : nums[i];
    }
    return res;
}

export function parseInt(x) {
    let res = typeof (x) === 'number' ? x : Number(x);
    return Math.round(res);
}

// https://stackoverflow.com/questions/5515869/string-length-in-bytes-in-javascript
export function byteLength(str) {
    // returns the byte length of an utf8 string
    var s = str.length;
    for (var i = str.length - 1; i >= 0; i--) {
        var code = str.charCodeAt(i);
        if (code > 0x7f && code <= 0x7ff) s++;
        else if (code > 0x7ff && code <= 0xffff) s += 2;
        if (code >= 0xDC00 && code <= 0xDFFF) i--; //trail surrogate
    }
    return s;
}

export function getGlobalUserId() {
    return window.mouselogUserId ? window.mouselogUserId : "";
}

export function equalArray(array1, array2) {
    if (!array1 || !array2 || array1.length != array2.length) {
        return false
    }
    for (let i = 0; i < array1.length; ++i) {
        if (array1[i] != array2[i]) {
            return false;
        }
    }
    return true;
}

let keyCodes = {
    "Tab": 9,
    "Enter": 13,
    "Shift": 16,
    "Escape": 27,
    "ShiftLeft": 160,
    "ShiftRight": 161,

    // edit
    "Backspace": 8,
    "Insert": 8, // 45
    "Delete": 8, // 46

    // cursor
    "Space": 32,
    "ArrowLeft": 32, // 37
    "ArrowUp": 32, // 38
    "ArrowRight": 32, // 38
    "ArrowDown": 32, // 40
    "PageUp": 32, // 33
    "PageDown": 32, // 34
    "End": 32, // 35
    "Home": 32, // 36

    // symbol
    "NumpadAdd": 107,
    "NumpadComma": 107, // 108
    "NumpadSubtract": 107, // 109
    "NumpadDecimal": 107, // 110
    "NumpadDivide": 107, // 111
    "Semicolon": 107, // 186
    "Equal": 107, // 187
    "Comma": 107, // 188
    "Minus": 107, // 189
    "Period": 107, // 190
    "Slash": 107, // 191
    "Backquote": 107, // 192
    "BracketLeft": 107, // 219
    "Backslash": 107, // 220
    "BracketRight": 107, // 221
    "Quote": 107, // 222

    // other
    "Control": 17,
    "Alt": 17,
    "CapsLock": 17,
    "PrintScreen": 17,
    "MetaLeft": 17,
    "MetaRight": 17,
    "NumpadMultiply": 17,
    "NumLock": 17,
    "ScrollLock": 17,
    "ControlLeft": 17,
    "ControlRight": 17,
    "AltLeft": 17,
    "AltRight": 17,
};

for (let i = 0; i < 10; i++) {
    keyCodes["Digit" + i] = 48 + i;
    keyCodes["Numpad" + i] = 96 + i;
}
for (let i = 1; i <= 24; i++)
    keyCodes["F" + i] = i + 111;
for (let i = 65; i <= 90; i++)
    keyCodes["Key" + String.fromCharCode(i)] = i;

export function getKeyCode(evt) {
    // keyCode is deprecated
    // But IE don't support keyboardEvent.code
    if (evt.keyCode !== undefined)
        return evt.keyCode;
    if (keyCodes[evt.code] !== undefined)
        return keyCodes[evt.code];
    return 0;
}

export function base64Encode(str) {
    return encodeURIComponent(window.btoa(unescape(encodeURIComponent(str))));
}

export function lzStringEncode(str) {
    return LZString.compressToEncodedURIComponent(str);
}